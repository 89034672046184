<template>
	<div class="page_container">
		<div class="page_title_bg">
			<div class="page_title_flag" />
			<div class="page_title">新闻资讯</div>
		</div>
		<div class="page_news">
			<a class="page_new_item" v-for="item of news" :key="item.id" @click="handleNewItem(item.id)">
				<div class="page_new_item_title">{{ item.title }}</div>
				<div class="page_new_item_date">{{ item.create }}</div>
			</a>
			<el-pagination
				class="page_new_pagination"
				background
				layout="prev, pager, next"
				:total="newsCount">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import { news_info } from '@/utils/info.js';

export default {
	name: 'News',
	data() {
	    return {};
	},
	computed: {
		news: () => news_info,
		newsCount: () => news_info.length,
	},
	mounted() {
	
	},
	methods: {
		handleNewItem(itemId){
			this.$router.push(`/detail/${itemId}`);
		}
	},
}
</script>

<style lang="scss" scoped>
.page_container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 80px auto 0;
	max-width: 1200px;
	.page_title_bg{
		margin-top: 32px;
		display: flex;
		align-items: center;
		.page_title_flag{
			margin-right: 5px;
			width: 22px;
			height: 64px;
			background-color: #409eff;
		}
		.page_title{
			font-size: 42px;
			color:#444444; 
			font-family:Microsoft YaHei;
			font-weight: bold;
		}
	}
	.page_news{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 20px;
		.page_new_item{
			margin: 20px 12px 0;
			padding-bottom: 30px;
			width: calc(100% - 24px);
			border-bottom: 1px solid #ddd;
			color: #404040;
			.page_new_item_title{
				margin-bottom: 12px;
				line-height: 26px;
				display: block;
				font-family: 'Microsoft YaHei';
				font-size: 22px;
				font-style: normal;
				font-weight: normal;
				text-decoration: none;
				transition: all 200ms linear;
			}
			.page_new_item_date{
				padding: 0;
				margin: 0;
				display: inline-block;
				font-family: 'Microsoft YaHei';
				font-size: 12px;
				font-style: normal;
				font-weight: normal;
				color: #bbb;
				text-decoration: none;
			}
			&:active{
				opacity: 0.8;
			}
		}
		.page_new_pagination{
			align-self: center;
			margin: 30px 0 50px;
		}
	}
}
@media (max-width:940px) {
	.page_container{
		.page_title_bg{
			.page_title_flag{
				margin-left: 12px;
				width: 14px;
				height: 42px;
			}
			.page_title{
				font-size: 28px;
			}
		}
		.page_news{
			.page_new_item{
				margin: 0;
				padding: 20px 16px 30px;
				.page_new_item_title{
					font-size: 20px;
				}
				&:active{
					opacity: 0.8;
					background-color: #ccc;
				}
			}
		}
	}
}
</style>